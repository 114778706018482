import { createAction } from '@reduxjs/toolkit';
import uuid from 'uuid/v4';
import apiWrapper from '../components/helpers/apiWrapper';
import { showErrorMessage, showSuccessMessage } from './messages';

const loadQuoteProductsAction = createAction('LOAD_QUOTE_PRODUCTS');
const resetProducts = createAction('RESET_PRODUCTS');
const deleteRemovedProductsAction = createAction('DELETE_PRODUCTS');
const updateProductAction = createAction('UPDATE_PRODUCT');
const saveEditedProductAction = createAction('SAVE_EDITED_PRODUCT');
const startImageUploadAction = createAction('START_IMAGE_UPLOAD');
const finishImageUploadAction = createAction('FINISH_IMAGE_UPLOAD');
const startFinishUploadAction = createAction('START_FINISH_UPLOAD');
const finishFinishUploadAction = createAction('FINISH_FINISH_UPLOAD');
const moveProductAction = createAction('MOVE_PRODUCT');
const changeProductGroupAction = createAction('CHANGE_GROUP');
const addProductToRemovedAction = createAction('ADD_PRODUCT_TO_REMOVED');
const duplicateProductAction = createAction('DUPLICATE_PRODUCT');
const addSelectedProductsAction = createAction('ADD_SELECTED_PRODUCTS');
const saveQuoteProductsAction = createAction('SAVE_QUOTE_PRODUCTS');
const setProductsLoadingAction = createAction('SET_PRODUCTS_LOADING');
const unsetProductsLoadingAction = createAction('UNSET_PRODUCTS_LOADING');

const getQuoteProducts = (quoteId, includeHighCost = true) => dispatch => {
  return apiWrapper
    .callApiPost(`/api/quoteProducts/getQuoteProducts`, {
      quoteId,
      includeHighCost,
    })
    .then(result => {
      return dispatch(loadQuoteProductsAction(result));
    })
};

const deleteRemovedProducts = (quoteId, productsToDelete) => dispatch => {
  return apiWrapper
    .callApiPost(`/api/quoteProducts/deleteRemovedProducts`, {
      quoteId,
      productsToDelete,
    })
    .then(() => {
      return dispatch(deleteRemovedProductsAction());
    });
};

const updateProduct = (id, field) => {
  return updateProductAction({ id, field });
};

const saveEditedProduct = id => {
  return saveEditedProductAction(id);
};

const uploadQuoteProductImage = (id, file) => async dispatch => {
  if (!(file instanceof Blob || file instanceof File)) {
    return;
  }

  dispatch(startImageUploadAction(id));

  const data = new FormData();

  data.append('product_id', id);
  data.append('image', file, file.name);

  const { image } = await apiWrapper.callApiPost(
    `/api/quoteProducts/uploadQuoteImages`,
    data,
    false
  );

  dispatch(
    updateProductAction({
      id,
      field: {
        image,
      },
    })
  );

  dispatch(finishImageUploadAction(id));

  // eslint-disable-next-line
  return image;
};

const uploadFinishes = (id, quoteId, finishes) => async dispatch => {
  dispatch(startFinishUploadAction(id));

  const finishData = new FormData();

  finishData.append('product_id', id);
  finishData.append('quote_id', quoteId);

  finishes.forEach((finish, i) => {
    if (finish.id) {
      finishData.append(`id_${i}`, finish.id);
    }
    if (finish.upload_file) {
      finishData.append(
        `image_${i}`,
        finish.upload_file,
        finish.upload_file.name
      );
    } else if (finish.finish_swatch_image) {
      finishData.append(`image_${i}`, finish.finish_swatch_image);
    }

    if (finish.finish_title) {
      finishData.append(`title_${i}`, finish.finish_title);
    }
  });

  const finishesUpdate = await apiWrapper.callApiPost(
    `/api/quotes/saveFinishes`,
    finishData,
    false
  );

  if (finishesUpdate.error) {
    dispatch(showErrorMessage(finishesUpdate.error));

    return;
  }

  const updatedFinishes = finishes.map(finish => {
    const updatedFinish = finishesUpdate.find(
      finishUpdate => finish.finish_number === finishUpdate.finish_number
    );

    return updatedFinish || finish;
  });

  dispatch(
    updateProductAction({
      id,
      field: {
        finishes: updatedFinishes,
      },
    })
  );

  dispatch(finishFinishUploadAction(id));

  // eslint-disable-next-line
  return updatedFinishes;
};

const moveProduct = (products, groups, position, positionShift) => dispatch => {
  if (typeof position === 'undefined') {
    return;
  }

  const newGroupId = getNewProductGroupId(
    products,
    groups,
    position,
    position + positionShift
  );

  if (newGroupId) {
    dispatch(changeProductGroupAction({ position, newGroupId }));
  } else if (canMoveProduct(position + positionShift, products.length)) {
    dispatch(moveProductAction({ position, positionShift }));
  }
};

const canMoveProduct = (newPosition, productsQty) => {
  return newPosition >= 0 && newPosition < productsQty;
};

const getNewProductGroupId = (products, groups, position, newPosition) => {
  const theGroup = getProductGroupBoundaries(products, position, groups);

  if (!theGroup) {
    return null;
  }

  const {
    firstProductInGroupPosition,
    lastProductInGroupPosition,
    groupPosition,
  } = theGroup;

  let newGroupId = null;

  // when we need to move the product to the next group
  // lastProductInGroupPosition - 1 because we count the products that stay in the group
  if (newPosition > lastProductInGroupPosition - 1) {
    newGroupId = getGroupIdToSwitch(groups, groupPosition, 1);
  } else if (newPosition < firstProductInGroupPosition) {
    // when we need to move the product to the previous group
    newGroupId = getGroupIdToSwitch(groups, groupPosition, -1);
  }

  return newGroupId;
};

function getGroupIdToSwitch(groups, groupPosition, positionShift) {
  const theGroup = groups[groupPosition + positionShift];

  if (!theGroup) {
    return false;
  }

  return theGroup.id;
}

// TODO: can just get the first and the last positions of products in the group and see if the product within
// get the data we need to know about the group to see where we need to switch the group
function getProductGroupBoundaries(products, productIndex, groups) {
  const groupId = getCurrentProductGroupId(products[productIndex]);

  if (!groupId) {
    return;
  }

  const groupPosition = groups.findIndex(group => group.id === groupId);

  if (groupPosition < 0) {
    return;
  }

  const firstProductInGroupPosition = products.findIndex(
    item => item.group_id === groupId
  );
  const lastProductInGroupPosition =
    products.length -
    products
      .slice()
      .reverse()
      .findIndex(item => item.group_id === groupId);

  // eslint-disable-next-line
  return {
    group_id: groupId,
    groupPosition,
    firstProductInGroupPosition,
    lastProductInGroupPosition,
  };
}

function getCurrentProductGroupId(product) {
  const { group_id = null } = product || {};

  if (!group_id || group_id < 0) {
    return null;
  }

  return group_id;
}

function deleteProduct(id) {
  if (
    // eslint-disable-next-line
    !window.confirm('Do you really want to remove the product from the quote?')
  ) {
    return null;
  }

  return addProductToRemovedAction(id);
}

const updateMainProduct = product => dispatch => {
  if (
    // eslint-disable-next-line
    !window.confirm(
      'Are you sure you want to update the product master database with all with all the data from this product?'
    )
  ) {
    return;
  }

  const productToSave = { ...product };

  // we will not need quote_products id in products table
  delete productToSave.id;

  return apiWrapper
    .callApiPost(`/api/products/edit/${product.product_id}`, productToSave)
    .then(() => {
      dispatch(
        showSuccessMessage(
          'The product has been updated in the master database'
        )
      );
    })
    .catch(e => {
      console.log(e);
      dispatch(
        showErrorMessage('There was an error updating the original product')
      );
    });
};

const createNewProduct = product => dispatch => {
  if (
    // eslint-disable-next-line
    !window.confirm(
      'Are you sure you want to create a new product with this data?'
    )
  ) {
    return;
  }

  const productToSave = { ...product, product_id: null };

  // we will not need quote_products id in products table
  delete productToSave.id;

  return apiWrapper
    .callApiPost(`/api/products/edit/${product.product_id}`, productToSave)
    .then(() => {
      dispatch(showSuccessMessage('The product has been created'));
    })
    .catch(e => {
      console.log(e);
      dispatch(showErrorMessage('There was an error creating the product'));
    });
};

const addSelectedProducts = (
  selectedProductsIds,
  groupId
) => async dispatch => {
  try {
    const selectedProducts = await apiWrapper.callApiPost(
      '/api/quotes/getSelectedProducts',
      selectedProductsIds
    );
    const currencies = await apiWrapper.callApi('/api/settings/currencies');

    const currenciesJson = currencies.map(c => {
      const key = `${c.from}/${c.to}`;
      const obj = {};
      obj[key] = c.rate;

      return obj;
    });

    // put these products to the first group we find and add temporary id
    const productsToAdd = selectedProducts.map(product =>
      Object.assign({}, product, {
        group_id: groupId,
        id: uuid(),
        tmp_id: true,
        currency_rates: currenciesJson,
        quantity: 1,
        finishes: [
          { finish_number: 0, finish_title: '', finish_swatch_image: '' },
          { finish_number: 1, finish_title: '', finish_swatch_image: '' },
          { finish_number: 2, finish_title: '', finish_swatch_image: '' },
        ],
      })
    );

    dispatch(addSelectedProductsAction({ products: productsToAdd, groupId }));

    sessionStorage.removeItem('selectedProducts');
  } catch (e) {
    console.log(e.message);
    dispatch(addSelectedProductsAction({ products: [] }));
  }
};

const saveQuoteProducts = (quoteId, products) => dispatch => {
  return apiWrapper
    .callApiPost(`/api/quoteProducts/saveQuoteProducts`, {
      products,
      quoteId,
    })
    .then(() => {
      return dispatch(saveQuoteProductsAction());
    });
};

const notifyManager = ({
  userId,
  customerName,
  quoteName,
  slug,
  randslug,
  reason = 'note',
}) => {
  return apiWrapper.callApiPost(`/api/quotes/notify`, {
    userId,
    customerName,
    quoteName,
    slug,
    randslug,
    reason,
  });
};

const updateNote = (quoteId, target) => dispatch => {
  const { name: id, value } = target;

  dispatch(
    updateProductAction({
      id,
      field: {
        client_note: value,
      },
    })
  );

  return apiWrapper.callApiPost('/api/products/updateNote', {
    quoteId,
    productId: id,
    note: value,
  });
};

const updateProductNote = (quoteId, id, value) => dispatch => {
  dispatch(
    updateProductAction({
      id,
      field: {
        client_note: value,
      },
    })
  );

  return apiWrapper.callApiPost('/api/products/updateNote', {
    quoteId,
    productId: id,
    note: value,
  });
};

export {
  getQuoteProducts,
  loadQuoteProductsAction,
  resetProducts,
  deleteRemovedProducts,
  deleteRemovedProductsAction,
  updateProductAction,
  updateProduct,
  saveEditedProductAction,
  saveEditedProduct,
  uploadQuoteProductImage,
  startImageUploadAction,
  finishImageUploadAction,
  startFinishUploadAction,
  finishFinishUploadAction,
  uploadFinishes,
  moveProductAction,
  moveProduct,
  changeProductGroupAction,
  addProductToRemovedAction,
  deleteProduct,
  duplicateProductAction,
  updateMainProduct,
  addSelectedProducts,
  addSelectedProductsAction,
  saveQuoteProducts,
  updateNote,
  updateProductNote,
  notifyManager,
  createNewProduct,
  setProductsLoadingAction,
  unsetProductsLoadingAction,
};
